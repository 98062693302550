<template>
    <div class="page-content w-100">
  <!-- start heading -->
  <div class="row">
    <div class="col-sm-12 mb-3">
      <p class="text-center page-heading">{{ doctorData?.customer?.companyShortName }}</p>
    </div>
  </div>
  <div class="mobie-setting-mainDiv" v-for="item in manage" :key="item">
        <!-- <router-link to="/clinicianothers/manage/team" > -->
          <div style="color: #000; cursor: auto;" v-if="item.name == 'Team'" class="SettingName">Team</div>
        <!-- </router-link> -->
        <router-link to="/doctor/manage/partner-clinic" v-if="item.name == 'Partner clinic'">
            <div  class="SettingName">Partner clinics</div>
        </router-link>
        <router-link to="/doctor/manage/inventory" v-if="item.name === 'Inventory' && (doctorData?.customer?.companyShortName === 'FootSecure' || doctorData?.customer?.companyShortName === 'Foot Secure')">
            <div  class="SettingName">Inventory</div>
        </router-link>
        <router-link v-else-if="item.name == 'Inventory'">
            <div style="color: #000; cursor: auto;" class="SettingName">Inventory</div>
        </router-link>
        <router-link to="/doctor/manage/orders" v-if="item.name == 'Orders'">
            <div class="SettingName">Orders</div>
        </router-link>
        <router-link to="/clinicianothers/messages" v-if="item.name == 'Library'">
            <div class="SettingName">Library</div>
        </router-link>
      </div>
  
</div>
<doctorfooter></doctorfooter>
</template>

<script>
import doctorfooter from "./doctor-footer.vue";
import axios from 'axios';
export default {
components: {
    doctorfooter
},
data() {
    return {
        doctorData:{},
        manage:[],
    }
},
created: function () {
    let DoctorUserId = localStorage.getItem("id");
          this.DoctorId = JSON.parse(DoctorUserId);
           if(this.DoctorId){
      this.getDoctors();
     } 
},
computed: {
},
methods: {
    getDoctors(){
  const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
     axios
     .get(`${BASE_API_URL}/hcps/${this.DoctorId}/one`)
    .then((response) => {
        this.doctorData=response.data
        this.manage=this.doctorData?.customer?.manage
    })
},
 
}
};
</script>
<style>
.SettingName {
    font-size: 19px;
    color: #00979e;
    padding: 0.25rem 1rem;
    cursor: pointer;
  }
  
  /* .mobie-setting-mainDiv {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  } */
</style>